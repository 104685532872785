/* Miscellanoeous */
body,
html {
  overflow-x: hidden;
  padding-right: 0;
}

body {
  background: $content-bg;

  scrollbar-width: thin; 
  scrollbar-color: #ccc #f9f9f9;
}

body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

*:-moz-full-screen,
*:-webkit-full-screen,
*:fullscreen *:-ms-fullscreen {
  overflow: auto;
}

.page-body-wrapper {
  min-height: calc(100vh - #{$navbar-height});
  padding-top: $navbar-height;
  @include display-flex();
  @include flex-direction(row);
  padding-left: 0;
  padding-right: 0;

  &.full-page-wrapper {
    min-height: 100vh;
    padding-top: 0;
    .main-panel {
      width: 100%;
      transition: none;
      .content-wrapper {
        padding: 0;
        > div {
          height: 100%;
        }
      }
    }
  }
}

.main-panel {
  background-color: #eee;
  transition: width $action-transition-duration $action-transition-timing-function, margin $action-transition-duration $action-transition-timing-function;
  width: calc(100% - #{$sidebar-width-lg});
  min-height: calc(100vh - #{$navbar-height});
  @include display-flex();
  @include flex-direction(column);
  @media (max-width: 991px) {
    margin-left: 0;
    width: 100%;
  }
  &.main-panel-only {
    transition: none;
  }
}

.content-wrapper {
  background: $content-bg;
  max-height: calc(100vh - 52px);
  overflow-y: auto;

  @media (min-width: 620px) {
    padding: 0 8px 0 8px;
  }

  padding-top: 0;
  margin-top:-15px;
  width: 100%;
  @include flex-grow(1);

  scrollbar-width: thin; /* Largura da barra de rolagem */
  scrollbar-color: #ccc #f9f9f9;
}

.content-wrapper::-webkit-scrollbar {
  width: 8px; /* Largura da barra de rolagem */
}

.content-wrapper::-webkit-scrollbar-thumb {
  background-color: #ccc; /* Cor da barra de rolagem */
  border-radius: 4px; /* Arredondamento das bordas */
}

.content-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: #aaa; /* Cor da barra de rolagem ao passar o mouse */
}

.hover-btn-rls {
  svg > path {
    stroke: #555 !important;
  }

  &:hover svg > path {
    stroke: #111 !important;
  }
}

input[type="date"] {
  position: relative;
}

input[type="date"]:after {
  color: #555;
  padding: 0 5px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
}


.content-route {
  background: $content-bg;
  height: 100vh;
  @media (min-width: 620px) {
    padding: 0 8px 0 8px;
  }

  padding-top: 0;
  padding: 0 !important;
  width: 100%;
  @include flex-grow(1);
}

.container-scroller {
  overflow: hidden;
}

pre {
  background: color(gray-lighter);
  padding: 15px;
  font-size: 14px;
}

code {
  padding: 5px;
  color: theme-color(danger);
  font-family: $type1;
  font-weight: 300;
  font-size: $default-font-size;
  border-radius: 4px;
}

.page-header {
  @extend .d-flex;
  @extend .justify-content-between;
  @extend .align-items-center;
  margin: 0 0 1.5rem 0;
  .breadcrumb {
    border: 0;
    margin-bottom: 0;
  }
}

.page-title {
  color: $black;
  font-size: 1.125rem;
  margin-bottom: 0;
  .page-title-icon {
    display: inline-block;
    width: 36px;
    height: 36px;
    border-radius: 4px;
    text-align: center;
    box-shadow: 0px 3px 8.3px 0.7px rgba(163, 93, 255, 0.35);
    i {
      font-size: .9375rem;
      line-height: 36px;
    }
  }
}
.dropdown-toggle {
  &.hide-carret {
    &:after {
      content: '';
      display: none;
    }
  }
}

.bg-transparent {
  background: transparent;
  
}

li.highlightOption  {
  background-color: #fff !important;
  color: #333 !important;
  cursor: pointer !important; 
}

li.option:hover {
  background-color: #DEEBfF !important;
  color: #333 !important;
  cursor: pointer !important;
}

.option.selected:hover {
  background-color: #DEEBfF !important;
  color: #333 !important;
}
.option-embedded-btn-signature {
  height: 40px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  cursor: pointer;
  transition: .2s ease all;
  background-color: transparent;
  min-width: 165px;
  background-color: rgba(0,0,0,.01);
}
.option-embedded-btn-signature:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.option-embedded-btn-signature-metric {
  height: 40px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  cursor: pointer;
  transition: .2s ease all;
  background-color: transparent;
  min-width: 140x;
  background-color: rgba(0,0,0,.01);
}
.option-embedded-btn-signature-metric:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.option-embedded-btn-signature-metric-view {
  height: 40px;
  width: 159.22px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  transition: .2s ease all;
  background-color: transparent;

  p, svg {
    color: #444 !important;
    font-size: 12px !important;
  }
}
.option-embedded-btn-signature-metric-view:hover {
  background-color: #ebebeb;
  // box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.option-embedded-btn {
  height: 40px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  cursor: pointer;
  transition: .2s ease all;
  background-color: transparent;
  background-color: rgba(0,0,0,.01);
}
.option-embedded-btn:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}
.option-embedded-btn-info {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 13px;
  color: #777;
  min-width: 40px;
}
.option-embedded-btn-down {
  padding: 2px;
}

.navbar-item-icon-only {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  min-width: 40px;
  background-color: transparent;
  cursor: pointer;
  background-color: rgba(0,0,0,.01);

  color: #777;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  }
}

.rls-item-function {
  gap: 10px;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-bottom: 0.5rem;
  height: 38px;
  min-height: 38px;
  border: 1px solid #ccc;
  background-color: transparent;
  border-radius: 4px;
  padding: 1% 2%;
  cursor: pointer;
  transition: .2s ease all;
  font-size: 80%;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    background-color: rgba(0,0,0,.035);
  }
}

.rls-item-function-active {
  gap: 10px;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-bottom: 0.5rem;
  height: 38px;
  min-height: 38px;
  border: 1px solid #aaa;
  background-color: rgba(0,0,0,.065);
  border-radius: 4px;
  padding: 1% 2%;
  font-size: 80%;

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 90%;
  }
}

.rls-item-function-button {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  min-height: 38px;
  background-color: #0062ff;
  border-radius: 2px;
  padding: 1% 2%;
  cursor: pointer;
  color: #eee;
  font-weight: 600;
}

.loading-line-bar {
  height: 3px;
  background: #888;
  animation: loading 1.5s forwards cubic-bezier(0,0,0,0);
}

@keyframes loading{
  0% {
    width: 0%;
  } 100% {
    width: 200px;
  }
}

@keyframes shimmer {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}
@keyframes shimmer-border {
  0% {
    background-position: 0 100%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 100%;
  }
}

.shimmer-item-effect {
  position: relative;
  animation: shimmer-border 2.5s infinite;
  background: linear-gradient(90deg, transparent 25%, rgba(0, 0, 0, 0.1) 60%, transparent 75%);
  background-size: 200% 100%;
  height: 13px; 
  width: 200px; 
  border-radius: 3px;
}

.hover-it-item {

  cursor: pointer;
  transition: .2s ease-in-out all;
  border-bottom: 1px solid transparent;

  &:hover {
    background-color: rgba(0,0,0,.05);
  }
} 

.hover-letter-item {
  width: 100%;
  cursor: pointer;
  transition: .2s ease-in-out all;
  
  &:hover {
    * {
      transition: .2s ease-in-out all;
      color: #348472 !important;
    }
  }
}

.letter-item-active {
  &:hover {
    * {
      transition: .2s ease-in-out all;
      opacity: .6;
    }  
  }

  * {
    cursor: pointer;
    transition: .2s ease-in-out all;
    font-weight: bold;
    color: #348472 !important;
  }
}

.btn-rls-apply { 
  background-color: #348472;
  height: 30px;
  width: 120px;
  border-radius: 2px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  transition: .2s ease-in-out all;

  p {
    color: #fff;
    font-size: 80%;
    font-weight: bold;
  }

  &:hover {
    opacity: .85;
  }
}

.nav-bullet-point {
  position: absolute;

  top: 6px;
  right: 0;

  width: 8px;
  height: 8px;
  border-radius: 15px;
  background-color: rgba(116, 129, 226, .7);

  display: flex;
  align-items: center;
  justify-content: center;

  i {
      font-size: 70%;
      color: #fff;
  }

  .nav-ring {
      position: absolute;
      background-color: inherit;
      border: 1px solid #7481e2; 
      width: 100%;
      height: 100%;
      border-radius: 100%;
      opacity: 0.7;
      animation: pulsing 2.5s ease-in-out infinite;
  }
  .ring:nth-of-type(1) {
      animation-delay: -1s;
  }
  .ring:nth-of-type(2) {
      animation-delay: -2s;
  }
  .ring:nth-of-type(3) {
      animation-delay: -3s;
  }
}