main.modal-report-edit-container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
    z-index: 999999999;
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.3);
    
    display: flex;
    align-items: center;
    justify-content: center;

    .modal-report-edit-content {
        min-height: 180px;
        width: 820px;
        padding: 30px 20px;

        border-radius: 3px;
        background-color: #fff;

        gap: 25px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;

        position: relative;

        header.report-edit-header {
            display: flex;
            flex-direction: column;

            h1.report-edit-title {
                color: #444;
                font-weight: 200;
                font-size: 22px;
                margin: 0;
                padding: 0;
                box-sizing: border-box;
            }

            p.report-edit-description {
                color: #626262;
            }
        }

        footer.report-edit-footer {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 10px;

            .report-edit-btn {
                height: 32px;
                padding: 0 22px;

                border-radius: 3px;

                border: 0;
                color: #fff;                
                transition: 0.3s ease-in-out all;

                &:hover {
                    opacity: 0.8;
                }

            }

            .close-btn-confirm {
                color: #333;
                background-color: transparent;
                border: 1px solid #9e9c9c;
                font-weight: 600;
            }

            .start-edit-btn {
                background-color: #0062ff;
            }

            .not-save-btn-confirm {
                color: #333;
                background-color: transparent;
                border: 1px solid #9e9c9c;
                font-weight: 600;
            }
        }
    }
}
