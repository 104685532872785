#buttonSaveModoEco {
    padding: 1em 3em;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-weight: 500;
    color: #fff;
    background-color: #003c7c;
    border: none;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
  }
  
  #buttonSaveModoEco:hover {
    background-color: #FFF;
    box-shadow: 0px 15px 20px rgba(0, 60, 124, 0.4);
    color: #000;
    transform: translateY(-1px);
  }
  
  #buttonSaveModoEco:active {
    transform: translateY(-1px);
  }
