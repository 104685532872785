@keyframes slideIn {
    to {
        transform: translateX(0%);
    }
}

.modal-signature-report-container {
    width: 100vw;
    height: 100vh;
    position: fixed;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 999999999;
    backdrop-filter: blur(5px);
    background-color: rgba(0,0,0, .3);

    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.modal-signature-report-content {
    width: 30%;
    height: 100%;
    min-height: 300px;
    min-width: 576px;
    background-color: #fff;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    padding: 60px 1% 15px 1%;

    transform: translateX(100%);
    animation: slideIn 0.3s ease-in-out forwards;
    position: relative;
}
.modal-signature-report-content-close-area {
    height: 5%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.modal-signature-report-content-close-area > i {
    font-size: 22px;
    color: #222;
    cursor: pointer; 
}

//header

.modal-signature-report-content-header {
    height: 10%;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba(100,100,100,.5);
}
p.signature-header-title { 
    font-size: 160%;
    color: #555;
    font-weight: bold;
}
p.signature-header-subtitle { 
    color: #444;
}

// not found
.signature-modal-not-found {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    gap: 15px;
}
.signature-modal-not-found > p.title {
    color: #555;
    font-size: 125%;
    font-weight: bold;
    text-align: center;
}
.signature-modal-not-found > p.subtitle {
    color: #555;
    text-align: center;
    margin: 0 10%;
}
.signature-modal-not-found > .btn-signature-not-found {
    padding: 5px 10px;
    cursor: pointer;
    background-color: #117865;
    border-radius: 4px;
    color: #fff;
}

.signature-modal-not-found > .btn-signature-not-found-loading {
    padding: 5px 10px;
    background-color: gray;
    border-radius: 4px;
    color: #fff;
}

.signature-modal-not-found > .btn-signature-not-found:hover {
    opacity: .9;
}
// item content 
.modal-signature-report-content-items {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 15px 0 0 0;
    overflow-y: auto;
    gap: 5px;
    margin-bottom: 15px;
}
.modal-signature-report-content-items::-webkit-scrollbar { 
	display: none;
}
// Footer
.modal-signature-footer {
    min-height: 45px;
    border-top: 1px solid rgba(100,100,100,.5);
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 10px;
}

// Signature form
.signature-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px 10px;
    border: 1px solid rgba(100,100,100,.5);
    border-radius: 3px;
}
.signature-form-header {
    min-height: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.signature-form-header > .down-bar {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.signature-form-header > .down-bar > p {
    font-size: 120%;
    color: #222;
    font-weight: normal;
}
.signature-form-header > .items {
    width: auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}
.signature-form-content {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.signature-simple-field {
    display: flex;
    flex-direction: column;
    min-height: 67px;

    .signature-field-flex {

        display: flex;
        flex-direction: column;

        gap: 10px;

        .signature-other-emails-list {

            display: flex;
            flex-direction: column;
            gap: 5px;

            .other-email-item {

                display: flex;
                flex-direction: column;

                border-radius: 3px;
                background-color: #f8f8f8;
                border: 1px solid #888;

                min-height: 28px;
                padding: 0 10px;

                i.mdi-close {
                    margin-right: 5px;
                }

                i.mdi-menu-down {
                    justify-self: flex-end;
                }

                i.mdi-shield-outline, i.mdi-shield {
                    margin-right: 5px;
                    font-size: 11px;
                }

                .other-email-item-upper {
                    
                    padding: 8px 0;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    
                    .other-email-item-left {
                        display: flex;
                        align-items: center;   
                        flex: 1; 
                        max-width: 90%;
    
                        .other-email-item-left-words {
                            display: flex;
                            flex-direction: column;
                            max-width: 100%;

                            p {
                                max-width: 100%;
                                line-height: normal;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
    
                            p.left-words-rls {
                                color: #777;
                                font-size: 11px;
                            }
                        }
                    }
                }

                .other-email-item-down {
                    width: 100%;
                    display: flex;
                    flex-direction: column;

                    padding-bottom: 8px;

                    .other-email-rls-item {
                        margin: 2px 0;

                        display: flex;
                        width: fit-content;
                        max-width: 100%;
                        align-items: center;
                        gap: 5px;
                        cursor: pointer;
                        transition: .3s ease-in-out;

                        &:hover {
                            background-color: rgba(0,0,0,.05);
                        }
                    }
                }


                p, i {
                    font-size: 12px;
                    margin: 0;
                    padding: 0;
                    line-height: normal;
                }

                i {
                    color: #222;
                    font-size: 12px;

                    cursor: pointer;
                    transition: .2s ease-in-out all;

                    &:hover {
                        color: #656565;
                    }
                }

                p {
                    color: #222;
                }
            }

        }

        .signature-field-flex-input-area {

            gap: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            input {
                width: 100%;
                color: #222;
                min-height: 36px;
                padding: 0 12px;
                border-radius: 3px;
                border: 1px solid #999;
            }
    
            .btn-new-email {
                height: 100%;
                width: 45px;
                border-radius: 3px;
        
                display: flex;
                align-items: center;
                justify-content: center;
        
                background-color: #117865;
    
                cursor: pointer;
    
                &:hover {
                    opacity: .85;
                }
            }
        }

    }

}
.signature-simple-field > label:first-child {
    margin: 0;
    padding: 0;
    font-size: 95%;
    color: #555;
}
.signature-simple-field > .signature-active-days-content {
    height: 30px;
    display: flex;
    gap: 5px;
}
.signature-simple-field > .signature-active-days-content > .item-day {
    flex: 1;
    color: #eee;
    background-color: #117865;

    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        opacity: .8;
    }
}
.signature-simple-field > .signature-active-days-content > .item-day-off {
    flex: 1;
    color: #000;
    background-color: gray;
    
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        opacity: .8;
    }
}
.signature-simple-field > label.second {
    font-size: 90%;
    color: #444;
    margin: 0;
    padding: 0;
}

.signature-simple-field > input {
    color: #222;
    min-height: 36px;
    padding: 0 12px;
    border-radius: 3px;
    border: 1px solid #999;
}
.signature-simple-field > select {
    color: #222;
    min-height: 36px;
    padding: 0 12px;
    border-radius: 3px;
    border: 1px solid #999;
    background-color: transparent;
}

section.section-signature {

    margin-top: 20px;
    display: flex;
    align-items: center;

    gap: 10px;

    label.section-label {
        margin: 0;
        font-size: 100%;
        color: #000;
        font-weight: bold;
    }

    i.line-section {
        height: 1px;
        flex: 1;
        border-bottom: 1px solid black;
    }
}
.signature-double-field {
    display: flex;
    flex-direction: row;
    gap: 5px;
    min-height: 67px;
}
.signature-double-child-field {
    width: 40%;
    display: flex;
    flex-direction: column;
}
.signature-double-child-field > label {
    font-size: 90%;
    color: #555;
    margin: 0;
    padding: 0;
}
.signature-double-child-field > input {
    color: #222;
    min-height: 36px;
    padding: 0 12px;
    border-radius: 3px;
    border: 1px solid #999;
}
.signature-double-child-field > input[type="date"] {
    background: url('data:image/svg+xml;charset=utf8,%3Csvg fill="%23000" fill-opacity=".54" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z"/%3E%3Cpath d="M0 0h24v24H0z" fill="none"/%3E%3C/svg%3E') no-repeat right center;
    background-size: 18px;
}
.signature-triple-field {
    display: flex;
    flex-direction: column;
    gap: 5px;
    min-height: 67px;
}
.signature-triple-field > label {
    margin: 0;
    padding: 0;
    font-size: 90%;
    color: #555;
}
.signature-triple-child-field {
    gap: 5px;
    display: flex;
    flex-direction: row;
}
.signature-triple-child-field > select {
    width: 15%;
    color: #222;
    min-height: 36px;
    padding: 0 12px;
    border-radius: 3px;
    border: 1px solid #999;
    background-color: transparent;
}
.signature-btn-locale {
    margin-top: 15px;
    gap: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.signature-field-btn-sucess {
    min-height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
    border-radius: 6px;
    background-color: #117865;
    cursor: pointer;
    transition: .2s ease-in-out all;

    &:hover {
        opacity: .85;
    }
}
.signature-field-info {
    min-height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
    border-radius: 99px;
    border: 1px solid #F4BB44;
    color: #F4BB44;
    gap: 5px;
}
.signature-field-btn-not-sucess {
    min-height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
    border-radius: 6px;
    background-color: #e84118;
    cursor: pointer;
}
.signature-field-btn-default {
    min-height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 12px;
    border-radius: 6px;
    color: #555;
    border: 1px solid rgba(100,100,100,.5);
    background-color: transparent;
    cursor: pointer;

    transition: .2s ease-in-out all;

    &:hover {
        border: 1px solid rgba(100,100,100,1);
        color: #222;
    }
}

// remove 
.btn-signature-confirm-delete {
    background-color: #e84118;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    border-radius: 99px;
    cursor: pointer;
    margin-right: 5px;
    
    p {
        all: unset;
        color: #fff;
        font-size: 8px;
    }
}

.warn-signature-item {
    margin-top: 15px;
    height: 34px;
    border-radius: 12px;
    background-color: rgba(255,120,0,.1);
    border: 1px solid #ff7800;

    padding: 0 10px;

    display: flex;
    align-items: center;

    color: #ff7800;

    gap: 10px;

    p {
        all: unset;
        font-size: 12px;
    }
}

.ocult-btn {
    display: flex;
    align-items: center;
    width: fit-content;

    gap: 3px;
    cursor: pointer;

    p.ocult-btn-word {
        color: #777;
        font-size: 12px;
    }

    i.mdi-chevron-up, i.mdi-chevron-down {
        color: #777;
        display: flex;
    }
}