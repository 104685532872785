@media screen and (max-width: 714px) and (max-height: 960px) {
	.powerbi-container {
		margin: 0;
		padding: 0;
		width: 100% !important;
		height: calc(50vh - 100px) !important;
	}
	
	.powerbi-content {
		margin: 0;
		padding: 0;
		width: 100% !important;
		height: calc(90vh - 100px) !important;
	}

	.powerbi-cotent-fullscreen {
		margin: 0;
		padding: 0;
		width: 100% !important;
		height: calc(100vh) !important;
	}
}

@media screen and (min-width: 715px) and (max-width: 1023px) and (max-height: 960px) {
	.powerbi-container {
		margin: 0;
		padding: 0;
		width: 100% !important;
		height: calc(60vh - 100px) !important;
	}
	
	.powerbi-content { // Iphone XR Retrato
		margin: 0;
		padding: 0;
		width: 100% !important;
		height: calc(150vh - 100px) !important;
	}
}

@media screen and (min-width: 1024px) and (max-width: 1279px) and (max-height: 960px) {
	.powerbi-container {
		margin: 0;
		padding: 0;
		width: 100% !important;
		height: calc(80vh - 100px) !important;
	}
	
	.powerbi-content {
		margin: 0;
		padding: 0;
		width: 100% !important;
		height: calc(100vh - 70px) !important;
	}
}

@media screen and (min-width: 1280px) {
	.powerbi-container {
		margin: 0;
		padding: 0;
		width: 100% !important;
		height: calc(100vh - 100px) !important;
	}
	
	.powerbi-content {
		margin: 0;
		padding: 0;
		width: 100% !important;
		height: calc(100vh - 70px) !important;
	}
}

.powerbi-content.hidden{
	display: none;
}

.powerbi-content iframe{
	height: min-content;
	border: none !important;
}

.reportContainerContent {
	background-color: blueviolet;
}


// full-screen

@media screen and (max-width: 714px) and (max-height: 960px) {
	.powerbi-content-fullscreeen {
		margin: 0;
		padding: 0;
		width: 100% !important;
		height: calc(100vh) !important;
	}
	
	.powerbi-content {
		margin: 0;
		padding: 0;
		width: 100% !important;
		height: calc(90vh - 100px) !important;
	}

	.powerbi-cotent-fullscreen {
		margin: 0;
		padding: 0;
		width: 100% !important;
		height: calc(100vh) !important;
	}
}

@media screen and (min-width: 715px) and (max-width: 1023px) and (max-height: 960px) {
	.powerbi-content-fullscreeen {
		margin: 0;
		padding: 0;
		width: 100% !important;
		height: calc(60vh - 100px) !important;
	}
	
	.powerbi-content-fullscreeen { // Iphone XR Retrato
		margin: 0;
		padding: 0;
		width: 100% !important;
		height: calc(100vh) !important;
	}
}

@media screen and (min-width: 1024px) and (max-width: 1279px) and (max-height: 960px) {
	.powerbi-content-fullscreeen {
		margin: 0;
		padding: 0;
		width: 100% !important;
		height: calc(100vh) !important;
	}
	
	.powerbi-content-fullscreeen {
		margin: 0;
		padding: 0;
		width: 100% !important;
		height: calc(100vh) !important;
	}
}

@media screen and (min-width: 1280px) {
	.powerbi-content-fullscreeen {
		margin: 0;
		padding: 0;
		width: 100% !important;
		height: calc(100vh) !important;
	}
	
	.powerbi-content-fullscreeen {
		margin: 0;
		padding: 0;
		width: 100% !important;
		height: calc(100vh) !important;
	}
}

.powerbi-content-fullscreeen.hidden{
	display: none;
}

.powerbi-content-fullscreeen iframe{
	height: min-content;
	border: none !important;
}

.reportContainerContent {
	background-color: blueviolet;
}
