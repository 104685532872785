.search-bar-result-item {
    color: #000;
    height: 50px;
    padding: 0 10px;
    font-size: 14px;
    
    cursor: pointer;

    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: space-between;
}

.search-bar-result-item-icon {

}

.search-bar-result-item-name {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.search-bar-result-item-name > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #555;
}

.search-bar-result-item-name-group {
    font-size: 10px;
    font-weight: 500;
}

.search-bar-result-item:hover {
    background-color: #e7e7e7;
}